import styled from 'styled-components'
import { IconButton, Box } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import Edit from '@material-ui/icons/Edit'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import React, { useEffect } from 'react'

const TaskTypesHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px;
`
const TaskTypesWrapper = styled.div`
  display: grid;
  align-items: center;
  width: 50%;
  margin: 10px;
  align-items: flex-start;
  flex-direction: column;'
`
const TaskTypesBody = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  justyfy-content: start;
  width: 100%;
  margin: 10px;
  flex-direction: column;
`

const TaskTypesForm = props => {
  const data =
    props.retailsConfig[props.selected_rid] &&
    props.retailsConfig[props.selected_rid].dynamic_task_views !== undefined
      ? props.retailsConfig[props.selected_rid].dynamic_task_views
      : []

  useEffect(() => {
    props.setAppTaskTypes(Object.assign(data, props.taskTypes))
  }, [props.selected_rid, props.retailsConfig])

  return (
    <TaskTypesWrapper>
      <TaskTypesHeader>
        <h3>Tasks Types(View)</h3>
        <IconButton
          onClick={() => {
            props.setTaskTypeModal(true)
          }}
          children={<Add style={{ fontSize: 25, color: 'green' }} />}
        />
      </TaskTypesHeader>
      <TaskTypesBody>
        <Box>
          <h5>Training</h5>
          <h5>VM</h5>
          <h5>Operational Task</h5>
          <h5>Data Task</h5>
        </Box>
        {props.taskTypes && (
          <Box>
            {props.taskTypes && (
              <Box>
                {props.taskTypes.map(taskType => {
                  return (
                    <Box display="flex" alignContent="center" height="24px">
                      <Box
                        display="flex"
                        id={taskType.task_view_id}
                        key={taskType.task_view_id}
                        onClick={e => {
                          props.setTaskTypeModalEdit(true)
                          props.setSelectedTypeTaskModal(
                            props.taskTypes.filter(
                              el => el.task_view_id == e.target.id
                            )
                          )
                        }}
                      >
                        <IconButton
                          disabled={true}
                          height="20px"
                          children={
                            <Edit
                              style={{
                                fontSize: 15,
                                color: 'gray',
                              }}
                            />
                          }
                        />
                      </Box>
                      <h5>{taskType.task_view_name}</h5>
                    </Box>
                  )
                })}
              </Box>
            )}
          </Box>
        )}
      </TaskTypesBody>
    </TaskTypesWrapper>
  )
}

const mapStateToProps = ({
  taskTypes,
  setTaskTypeModal,
  setTaskTypeModalEdit,
  setSelectedTypeTaskModal,
  selectedTypeTask,
  selected_rid,
  setAppTaskTypes,
  retailsConfig,
}) => ({
  taskTypes,
  setTaskTypeModal,
  setTaskTypeModalEdit,
  setSelectedTypeTaskModal,
  selectedTypeTask,
  selected_rid,
  setAppTaskTypes,
  retailsConfig,
})

export default connect(mapStateToProps, actions)(TaskTypesForm)
