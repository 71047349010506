import React from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'
import FullScreenLoader from '../components/FullScreenLoader'
import withRetailConfig from '../components/Elements/withRetailConfig'
import RetailConfigForm from '../components/Elements/Form/RetailConfigForm'

const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  width: 20%;
  margin: 10px;
`

const retailConfig = props => {
  return (
    <React.Fragment>
      <Layout>
        <HeaderStyled>
          <h1>Retail Config</h1>
        </HeaderStyled>
        <br />
        <br />

        <FullScreenLoader loading={props.loading} />
        {props.retailsConfig[props.selected_rid] !== null ? (
          <RetailConfigForm
            retailConfig={props.retailsConfig[props.selected_rid]}
            retailTags={props.retailTags}
          />
        ) : (
          'this retail has no config in this server '
        )}
      </Layout>
    </React.Fragment>
  )
}

export default withRetailConfig(retailConfig)
