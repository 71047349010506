import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import TaskTypeModalForm from './Form/TaskTypeModalForm'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

const TaskTypeModal = props => {
  const close = () => {
    props.closeTaskTypeModal()
  }

  return (
    <Modal
      backdrop={false}
      isOpen={props.showTaskTypeModal || props.editTaskTypeModal}
      toggle={close}
    >
      <ModalHeader toggle={close} />
      <ModalBody>
        <TaskTypeModalForm />
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = ({ showTaskTypeModal, editTaskTypeModal }) => ({
  showTaskTypeModal,
  editTaskTypeModal,
})

export default connect(mapStateToProps, actions)(TaskTypeModal)
