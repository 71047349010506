import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import '../styles/formStyle.css'
import Button from '../Buttons/Button'
import isNil from 'lodash/isNil'
import Switch from '../FormElements/Switch'
import { updateRetailConfig } from '../../../services/api'
import Keys from '../../../utils/Keys.json'
import styled from 'styled-components'
import { Divider, InputLabel } from '@material-ui/core'
import { convertUnderscoreNameToLabel } from '../../../utils/taskHandler'
import Checkbox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'
import Collapse from '../FormElements/Collapse'
import Input from '../FormElements/Input'
import TaskTypeModal from '../TaskTypeModal'
import TaskTypesForm from './TaskTypesForm'
import { GPTTemplate } from '../FormElements/GPT_Template'
import RetailTheme from '../FormElements/RetailTheme'
import { isEmpty } from 'lodash'
import { DEFAULT_THEME } from '../FormElements/RetailTheme/utils'

import MultiSelect from '@kenshooui/react-multi-select'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`
class RetailConfigForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ...this._setState() }
  }

  _setState = () => {
    return {
      is_cover_image_contain:
        !isNil(this.props.retailConfig) &&
        !isNil(this.props.retailConfig[Keys.IS_COVER_IMAGE_CONTAIN])
          ? this.props.retailConfig[Keys.IS_COVER_IMAGE_CONTAIN]
          : false,
      real_report:
        !isNil(this.props.retailConfig) &&
        !isNil(this.props.retailConfig[Keys.REAL_REPORT])
          ? this.props.retailConfig[Keys.REAL_REPORT]
          : false,
      is_collapse_posts:
        !isNil(this.props.retailConfig) &&
        !isNil(this.props.retailConfig[Keys.IS_COLLAPSE_POSTS])
          ? this.props.retailConfig[Keys.IS_COLLAPSE_POSTS]
          : false,
      is_personalization_mode:
        !isNil(this.props.retailConfig) &&
        !isNil(this.props.retailConfig[Keys.IS_PERSONALIZATION_MODE])
          ? this.props.retailConfig[Keys.IS_PERSONALIZATION_MODE]
          : false,
      real_report_fetch:
        !isNil(this.props.retailConfig) &&
        !isNil(this.props.retailConfig[Keys.REAL_REPORT_FETCH])
          ? this.props.retailConfig[Keys.REAL_REPORT_FETCH]
          : false,
      action_page:
        !isNil(this.props.retailConfig) &&
        !isNil(this.props.retailConfig[Keys.ACTION_PAGE])
          ? this.props.retailConfig[Keys.ACTION_PAGE]
          : [],
      reports_fetch_policy:
        !isNil(this.props.retailConfig) &&
        !isNil(this.props.retailConfig[Keys.REPORTS_FETCH_POLICY])
          ? this.props.retailConfig[Keys.REPORTS_FETCH_POLICY]
          : 'by_range',
      reports_fetch_by_range_value:
        !isNil(this.props.retailConfig) &&
        !isNil(this.props.retailConfig[Keys.REPORTS_FETCH_BY_RANGE_VALUE])
          ? this.props.retailConfig[Keys.REPORTS_FETCH_BY_RANGE_VALUE]
          : 7,
      icons_urls:
        !isNil(this.props.retailConfig) && this.props.retailConfig.icons_urls,
      rankingTags:
        this.props.retailConfig?.['ranking_tags'] &&
        this.props.retailTags?.length > 0
          ? this.props.retailTags.filter(tag =>
              this.props.retailConfig?.['ranking_tags']?.includes(tag.sk)
            )
          : [],
      reportsTags:
        this.props.retailConfig?.['reports_tags'] &&
        this.props.retailTags?.length > 0
          ? this.props.retailTags.filter(tag =>
              this.props.retailConfig?.['reports_tags']?.includes(tag.sk)
            )
          : [],
      gptTemplate:
        !isNil(this.props.retailConfig) &&
        this.props.retailConfig[Keys.GPT_TEMPLATE]
          ? this.props.retailConfig[Keys.GPT_TEMPLATE]
          : '',
      theme_config:
        !isNil(this.props.retailConfig) && this.props.retailConfig.theme_config
          ? this.props.retailConfig.theme_config
          : {},
    }
  }

  handleChangeThemeConfig = config => {
    this.setState({ theme_config: config })
  }
  handleChangeTemplate = e => {
    this.setState({ gptTemplate: e.target.value })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.selected_rid !== prevProps.selected_rid ||
      this.props.retailConfig !== prevProps.retailConfig
    ) {
      this.setState({
        ...this._setState(),
      })
    }
  }

  componentDidMount() {
    if (this.props.selected_rid !== null) {
      this.setState({
        ...this._setState(),
      })
    }
  }

  switchActive = e => {
    this.setState({ [e.target.value]: e.target.checked })
  }

  handleResponse = response => {
    const { selected_rid } = this.props
    if (response.status === 401) {
      this.props.logout()
    } else if (response.status === 200) {
      this.props.handleAlert({
        text: `Configurations was successfully changed`,
        type: Keys.ALERT_SUCCESS,
      })
      // this.props.setRetailConfig(selected_rid, this.state)
    } else {
      this.props.handleAlert({
        text: `Configurations was not changed, something when wrong :(`,
        type: Keys.ALERT_ERROR,
      })
    }
  }

  handleActionPageChange = e => {
    const { action_page } = this.state
    const val = Number(e.target.name)
    let temp_action_page
    if (!action_page.includes(Number(val))) {
      temp_action_page = [...action_page, val]
    } else {
      temp_action_page = action_page.filter(action => {
        return action !== val
      })
    }
    this.setState({ action_page: [...temp_action_page] })
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  validateFileldsOfTaskTypes = taskTypes => {
    let newObj = []
    for (let obj in taskTypes) {
      let item = taskTypes[obj]
      const taskType = {
        task_view_id: item.task_view_id,
        task_view_name: item.task_view_name,
        color: item.color,
        icon: item.icon,
        order: item.order,
      }
      newObj.push(taskType)
    }
    return newObj
  }
  compareArraysById(array1, array2) {
    if (array1.length !== array2.length) {
      return false
    }
    if (array1.length === 0 && array2.length === 0) {
      return true
    }
    return array1.every((obj1, index) => obj1.sk === array2[index].sk)
  }
  handelSubmit = event => {
    const { selected_rid } = this.props
    event.preventDefault()
    this.props.showFullScreenLoader()
    const {
      is_cover_image_contain,
      real_report,
      action_page,
      real_report_fetch,
      is_collapse_posts,
      is_personalization_mode,
      reports_fetch_policy,
      reports_fetch_by_range_value,
      rankingTags,
      reportsTags,
      gptTemplate,
    } = this.state

    // const initialRankingTags =
    //   this.props.retailTags.filter(tag =>
    //     this.props.retailConfig?.['ranking_tags']?.includes(tag.sk)
    //   ) || []
    const rankingTagsIds = rankingTags.map(tag => tag.sk)
    const reportsTagsIds = reportsTags.map(tag => tag.sk)

    // const isAddRankingTags = this.compareArraysById(
    //   initialRankingTags,
    //   rankingTags
    // )
    const config = {
      is_cover_image_contain: is_cover_image_contain,
      real_report: real_report,
      action_page: action_page,
      real_report_fetch: real_report_fetch,
      reports_fetch_policy: reports_fetch_policy,
      reports_fetch_by_range_value: Number(reports_fetch_by_range_value),
      dynamic_task_views: this.validateFileldsOfTaskTypes(this.props.taskTypes),
      ...(rankingTagsIds ? { ranking_tags: rankingTagsIds } : {}),
      ...(reportsTagsIds ? { reports_tags: reportsTagsIds } : {}),
      gpt_template: gptTemplate,
      is_collapse_posts: is_collapse_posts,
      is_personalization_mode: is_personalization_mode,
      theme_config: !isEmpty(this.state.theme_config)
        ? this.state.theme_config
        : DEFAULT_THEME,
    }
    const body = {
      rid: selected_rid,
      config: config,
    }

    updateRetailConfig(
      this.handleResponse,
      body,
      this.props.hideFullScreenLoader
    )
  }

  printReportRangesOptions = () => {
    let menuOptions = []
    const { reportFetchRangesMap } = this.props
    const { reports_fetch_policy } = this.state
    Object.keys(reportFetchRangesMap).forEach((item, index) => {
      menuOptions.push(
        <div className="menuOptionsSelectElement" key={index}>
          <Radio
            type="radio"
            id={item}
            name={'reports_fetch_policy'}
            color="default"
            value={reportFetchRangesMap[item]}
            checked={reports_fetch_policy === reportFetchRangesMap[item]}
            onChange={this.handleChange}
          />
          <label className="menuOptionsSelectLabel">
            {convertUnderscoreNameToLabel(reportFetchRangesMap[item])}
          </label>
        </div>
      )
    })

    return menuOptions
  }

  printCheckForm = (label, options, state) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <InputLabel shrink>{convertUnderscoreNameToLabel(label)}</InputLabel>
        <div
          style={{
            flexDirection: 'row',
            maxWidth: '70%',
          }}
        >
          {Object.keys(options).map(item => {
            return (
              <label key={item.toString()}>
                <Checkbox
                  type="radio"
                  name={item}
                  checked={state.includes(Number(item))}
                  onChange={this.handleActionPageChange}
                  onClick={this.handleActionPageChange}
                  color="primary"
                />
                {convertUnderscoreNameToLabel(options[item])}
              </label>
            )
          })}
        </div>
      </div>
    )
  }
  handleRankingTagsChange = selectedTags => {
    this.setState({
      rankingTags: selectedTags.map(obj => ({
        sk: obj.id,
        name: obj.label,
      })),
    })
  }
  handleReportsTagsChange = selectedTags => {
    this.setState({
      reportsTags: selectedTags.map(obj => ({
        sk: obj.id,
        name: obj.label,
      })),
    })
  }
  getTagsOptions = (childToConvert = null) => {
    let options = []
    const { retailTags } = this.props
    if (childToConvert !== null) {
      childToConvert.forEach(tag => {
        options.push({ id: tag.sk, label: tag.name })
      })
    } else {
      retailTags?.forEach(tag => {
        options.push({ id: tag.sk, label: tag.name })
      })
    }
    return options
  }

  render() {
    const {
      is_cover_image_contain,
      real_report,
      is_collapse_posts,
      action_page,
      real_report_fetch,
      reports_fetch_policy,
      reports_fetch_by_range_value,
      rankingTags,
      reportsTags,
      gptTemplate,
      is_personalization_mode,
    } = this.state
    const { actionPagesMap } = this.props
    return (
      <>
        <form className="itemForm" onSubmit={this.handelSubmit}>
          <ul style={{ maxWidth: '80%' }}>
            <div className="fullScreenButtonDash">
              <Button type="submit" label={'Save Item Changes'} />
            </div>
            <Row>
              <Switch
                value={'is_cover_image_contain'}
                label={'Is Image Contained'}
                onChange={this.switchActive}
                checked={is_cover_image_contain}
                required={true}
              />
              <Switch
                value={'real_report'}
                label={'Show Real Report page'}
                onChange={this.switchActive}
                checked={real_report}
                required={true}
              />
              <Collapse open={real_report}>
                <Switch
                  value={'real_report_fetch'}
                  label={'Fetch report data'}
                  onChange={this.switchActive}
                  checked={real_report_fetch}
                  required={true}
                />
              </Collapse>
              <Switch
                value={'is_collapse_posts'}
                label={'Collapse posts'}
                onChange={this.switchActive}
                checked={is_collapse_posts}
                required={true}
              />
              <Switch
                value={'is_personalization_mode'}
                label={'Personalization Mode'}
                onChange={this.switchActive}
                checked={is_personalization_mode}
                required={true}
              />

              {this.printCheckForm(
                Keys.ACTION_PAGE,
                actionPagesMap,
                action_page
              )}
            </Row>
            <br />
            <br />
            <Row>
              <div>
                <InputLabel shrink>Report Fetch Ranges</InputLabel>
                <Row>{this.printReportRangesOptions()}</Row>
              </div>
              <Collapse open={reports_fetch_policy === 'by_range'}>
                <Input
                  type={'number'}
                  label={'Fetch range (in days)'}
                  max={30}
                  name={'reports_fetch_by_range_value'}
                  onChange={this.handleChange}
                  value={reports_fetch_by_range_value}
                />
              </Collapse>
            </Row>
          </ul>
          <Row
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingBottom: '12px',
            }}
          >
            <span>Ranking Tags</span>
          </Row>
          <Divider light />
          <div
            style={{
              padding: '16px',
            }}
          >
            <MultiSelect
              onChange={this.handleRankingTagsChange}
              selectedItems={this.getTagsOptions(rankingTags)}
              items={this.getTagsOptions()}
            />
          </div>
          <Row
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingBottom: '12px',
            }}
          >
            <span>Reports Tags</span>
          </Row>
          <Divider light />
          <div
            style={{
              padding: '16px',
            }}
          >
            <MultiSelect
              onChange={this.handleReportsTagsChange}
              selectedItems={this.getTagsOptions(reportsTags)}
              items={this.getTagsOptions()}
            />
          </div>
        </form>
        <TaskTypesForm />
        <TaskTypeModal />
        <RetailTheme
          setTheme={this.handleChangeThemeConfig}
          theme={this.state.theme_config}
          selected_rid={this.props.selected_rid}
        />
        <GPTTemplate
          gptTemplate={gptTemplate}
          handleChange={this.handleChangeTemplate}
        />
      </>
    )
  }
}

const mapStateToProps = ({
  selected_rid,
  actionPagesMap,
  reportFetchRangesMap,
  loading,
  taskTypes,
  retailConfig,
  retailsConfig,
}) => ({
  selected_rid,
  actionPagesMap,
  reportFetchRangesMap,
  loading,
  taskTypes,
  retailConfig,
  retailsConfig,
})

export default connect(mapStateToProps, actions)(RetailConfigForm)
