import React from 'react'
import Button from '../Buttons/Button'
import { validate } from '../../../utils/taskHandler'
import Keys from '../../../utils/Keys'
import '../styles/formStyle.css'
import Input from '../FormElements/Input'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import { Box, Select } from '@material-ui/core'
import { colorsArr } from '../../../utils/taskTypesutils'
import _ from 'lodash'
import isNil from 'lodash/isNil'

class TaskTypeModalForm extends React.Component {
  constructor(props) {
    super(props)
    this.newDate = new Date()
    this.state = {
      isColorPickerOpen: false,
      isSvgListOpen: false,
      task_type_name:
        this.props.editTaskTypeModal === true
          ? this.props.selectedTypeTask[0].task_view_name
          : '',
      color:
        this.props.editTaskTypeModal === true
          ? this.props.selectedTypeTask[0].color
          : '',
      colorName:
        this.props.editTaskTypeModal === true
          ? this.props.selectedTypeTask[0].color_name
          : '',
      icon:
        this.props.editTaskTypeModal === true
          ? this.props.selectedTypeTask[0].icon
          : '',
      iconsUrls:
        !isNil(this.props.retailsConfig[this.props.selected_rid].icons_urls) &&
        this.props.retailsConfig[this.props.selected_rid].icons_urls,
      order:
        this.props.editTaskTypeModal === true
          ? this.props.selectedTypeTask[0].order
          : 0,
    }
  }
  getSecondsSinceEpoch(date) {
    return Math.floor(date / 1000)
  }
  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  handleColorPickerClick = e => {
    this.setState({
      color: e.target.style.backgroundColor,
      isColorPickerOpen: false,
    })
  }
  handleListClick = e => {
    this.setState({
      icon: e.target.src,
      isSvgListOpen: false,
    })
  }
  handleOpenColorPicker = () => {
    this.setState({ isColorPickerOpen: true })
  }
  handleOpenSvgList = () => {
    this.setState({ isSvgListOpen: true })
  }

  isValidate = form => {
    const toValidate = ['task_type_name']
    try {
      return validate(form, toValidate)
    } catch (e) {
      this.props.handleAlert({
        text: e.text,
        type: 'error',
      })
      return false
    }
  }

  preventSubmit = e => {
    const key = e.charCode || e.keyCode || 0
    if (key === 13) {
      e.preventDefault()
    }
  }

  handleSubmit = event => {
    event.preventDefault()
    const { taskTypes, editTaskTypeModal, selectedTypeTask } = this.props
    const { color, icon, task_type_name, order } = this.state
    let newObj = {
      task_view_name: task_type_name.trim(),
      color: color,
      icon: icon,
      order: order,
      task_view_id: this.getSecondsSinceEpoch(this.newDate),
    }

    let types = [...taskTypes]
    if (this.isValidate(this.state) === true) {
      if (editTaskTypeModal === true && selectedTypeTask) {
        let editedObject = {
          task_view_name: task_type_name.trim(),
          color: color,
          icon: icon,
          order: order,
          task_view_id: selectedTypeTask[0].task_view_id,
        }
        let indexOfEditedType = types.findIndex(
          el => el.task_view_id == selectedTypeTask[0].task_view_id
        )
        console.log(indexOfEditedType)
        types.splice(indexOfEditedType, 1)
        types.splice(editedObject.order, 0, editedObject)
        for (let index = 0; index <= types.length - 1; index++) {
          types[index].order = index
        }
      } else {
        types.splice(newObj.order, 0, newObj)
        for (let index = 0; index < types.length; index++) {
          types[index].order = index
        }
      }
      this.props.closeTaskTypeModal()
      this.props.setAppTaskTypes(types)
    }
  }

  handleDelete = event => {
    event.preventDefault()
    const filteredTasksTypes = this.props.taskTypes.filter(
      el => el.task_view_id !== this.props.selectedTypeTask[0].task_view_id
    )
    for (let index = 0; index <= filteredTasksTypes.length - 1; index++) {
      filteredTasksTypes[index].order = index
    }
    this.props.setAppTaskTypes(filteredTasksTypes)
    this.props.closeTaskTypeModal()
  }

  render() {
    return (
      <form
        className="itemForm"
        onSubmit={this.handleSubmit}
        onKeyPress={this.preventSubmit}
      >
        <ul className="formList">
          <Input
            value={this.state.task_type_name}
            onChange={this.handleChange}
            type="text"
            name={'task_type_name'}
            required={true}
            label={'Task Type'}
            autoFocus={true}
          />
          {this.state.iconsUrls && (
            <Box onClick={this.handleOpenSvgList}>
              <Input
                value={
                  this.state.icon
                    ? this.state.icon.match(/\/([^\/]+)\/?$/)[1]
                    : this.state.icon
                }
                required={true}
                label={'Icon'}
                autoFocus={true}
                autoComplete={'off'}
              />
            </Box>
          )}

          {this.state.isSvgListOpen === true && (
            <Box
              display="grid"
              gridTemplateColumns="repeat(4, 1fr)"
              gridTemplateRows="repeat(4, 1fr)"
              padding="15px"
              width="250px"
              border="1px solid black"
              gridGap={5}
              justifyContent="center"
            >
              {this.state.iconsUrls.map((svg, i) => {
                return (
                  <img
                    key={i}
                    src={svg}
                    id={svg}
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      filter: 'brightness(0)',
                    }}
                    onClick={this.handleListClick}
                  ></img>
                )
              })}
            </Box>
          )}
          <Select
            defaultValue={this.state.order}
            value={this.state.order}
            onChange={this.handleChange}
            type="number"
            name={Keys.ORDER}
            required={true}
            label={'Order'}
            style={{ width: '220px' }}
          >
            {this.props.editTaskTypeModal === false
              ? _.times(this.props.taskTypes.length + 1, i => {
                  return (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  )
                })
              : _.times(this.props.taskTypes.length, i => {
                  return (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  )
                })}
          </Select>
          <li className="formButtonDash">
            <Button
              type={'submit'}
              label={
                this.props.editTaskTypeModal ? 'Submit changes' : 'Add New Type'
              }
            />
            {this.props.editTaskTypeModal ? (
              <Button
                type="button"
                onClick={this.handleDelete}
                color={'secondary'}
                label={'Delete Type'}
              />
            ) : null}
          </li>
        </ul>
      </form>
    )
  }
}
const mapStateToProps = ({
  taskTypes,
  showTaskTypeModal,
  editTaskTypeModal,
  selectedTypeTask,
  retailsConfig,
  selected_rid,
}) => ({
  taskTypes,
  showTaskTypeModal,
  editTaskTypeModal,
  selectedTypeTask,
  retailsConfig,
  selected_rid,
})

export default connect(mapStateToProps, actions)(TaskTypeModalForm)
