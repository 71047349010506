import React from 'react'
import { PropTypes } from 'prop-types'
import '../styles/formStyle.css'
import styled from 'styled-components'

const CollapseDiv = styled.div`
  overflow: hidden;
  transition: max-height 0.5s ease-in-out; // note that we're transitioning max-height, not height!
  height: auto;
  max-height: ${props =>
    props.open ? props.maxHeight : '0px'}; // still have to hard-code a value!
`

const Collapse = props => {
  return (
    <CollapseDiv {...props} maxHeight={props.maxHeight}>
      {props.children}
    </CollapseDiv>
  )
}

Collapse.defaultProps = {
  maxHeight: '600px',
  open: false,
}

Collapse.propTypes = {
  maxHeight: PropTypes.string,
  open: PropTypes.bool,
}

export default Collapse
