import React from 'react'
import { Box, Typography, TextareaAutosize } from '@material-ui/core'
export const GPTTemplate = ({ gptTemplate, handleChange }) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      margin="20px"
    >
      <Typography style={{ fontSize: '24px', marginBottom: '10px' }}>
        GPT Template
      </Typography>
      <TextareaAutosize
        rowsMin={8}
        style={{ width: '40%' }}
        value={gptTemplate}
        onChange={e => handleChange(e)}
      />
    </Box>
  )
}
